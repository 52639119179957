<template>
    <div id="user-list-section" class="create-section mt-sm-5 mt-3">
        <div class="container">
            <form @submit.prevent="getList()">
                <div class="mb-3 row">
                    <div class="col-sm-2 mb-2 mb-sm-0">
                        <create-action v-if="$root.$can('role', 'role-create')"
                                       to_name="roleCreate" title="Role"/>
                    </div>
                    <div class="col-sm-3 ms-auto">
                        <input v-model="table.search" type="text" class="form-control mb-3" id="name"
                               placeholder="Searching...">
                    </div>
                    <div class="col-sm-2">
                        <input type="submit" class="btn btn-primary w-100" value="Searching">
                    </div>
                </div>
            </form>
            <div>
                <table class="table table-striped table-hover">
                    <thead>
                    <tr>
                        <th scope="col">#</th>
                        <th scope="col">Name</th>
                        <th scope="col" style="width: 80px">Edit</th>
                        <th scope="col" style="width: 80px;">Delete</th>
                    </tr>
                    </thead>
                    <tbody>
                    <template v-if="lists.length">
                        <tr v-for="(list, index) in lists">
                            <th scope="row">{{ table.pagination.from + index }}</th>
                            <td>{{ list.name }}</td>
                            <td>
                                <update-action v-if="$root.$can('role', 'role-update')"
                                               to_name="roleEdit" :params="{ id: list.id }"/>
                            </td>
                            <td>
                                <delete-action v-if="$root.$can('role', 'role-delete')" :id="list.id"/>
                            </td>
                        </tr>
                    </template>
                    <template v-if="!lists.length">
                        <tr>
                            <th class="text-center font-weight-bolder" colspan="100%">No data found</th>
                        </tr>
                    </template>
                    </tbody>
                </table>
            </div>
            <pagination v-if="lists.length>0" :pagination="table.pagination" @paginate="getList()" :offset="5"/>
        </div>
    </div>
</template>

<script>
import ApiService          from "@/services/api.service";
import NotificationService from "@/services/notification.service";
import Pagination          from "@/components/Pagination";

export default {
    name      : "Index",
    components: {Pagination},
    data      : () => ({
        lists: [],
        table: {
            per_page  : 10,
            search    : '',
            pagination: {
                current_page: 1
            }
        }
    }),
    mounted() {
        this.getList()
    },
    methods: {
        getList() {
            let params = {
                ...this.table,
                page: this.table.pagination.current_page,
            };
            ApiService.get('/roles', {params: params}).then((res) => {
                this.lists            = res.data.data;
                this.table.pagination = res.data.meta;
            }).catch(error => {
                NotificationService.error(error.response.data.message);
            })
        },
        destroy(id) {
            Swal.fire({
                title             : 'Are you sure?',
                text              : "You won't be able to revert this!",
                icon              : 'warning',
                showCancelButton  : true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor : '#d33',
                confirmButtonText : 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    ApiService.delete(`/roles/${id}`,).then(res => {
                        this.getList();
                        NotificationService.success(res.data.message);
                    }).catch(error => {
                        NotificationService.error(error.response.data.message);
                    })
                }
            }).catch(error => {
                NotificationService.error(error.response.data.message);
            })
        }
    }
}
</script>
